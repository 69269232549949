.login {
    height: 100%;
    /* background-image: url('../assets/testbg.png'); */
    background-image: url('../assets/macbg-blur.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif; 

    .header {
        position: absolute;
        top: 7px;
        right: 10px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.5px;
    }
    
    .icon {
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: text-top;
    }
    
    .battery {
        background-image: url('../assets/icon-battery.png');
    }
    
    .wifi {
        background-image: url('../assets/icon-wifi.png');
    }
    
    .middle {
        padding-top: 34vh;
    }
    
    .center {
        display: block;
        margin: 0 auto;
    }
    
    .text-center {
        text-align: center;
    }
    
    .name {
        margin: 24px 0;
        font-size: 15px;
        font-family: 'San Francisco', Helvetica, Arial, san-serif;
    }
    
    .jennysong {
        height: 80px;
        width: 80px;
        background-image: url('../assets/jennysong.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    
    .inline {
        display: inline-block;
        margin-left: 7px;
    }
    
    .textbox {
        font-size: 16px;
        background: #ffffff30;
        padding: 7px;
        border-radius: 7px;
        border: none;
    }
    
    .submitbox {
        width: 30px;
        height: 30px;
        margin: 0 7px;
        opacity: 0.5;
        vertical-align: bottom;
    }
    
    input:focus {
        outline: none;
    }
    
    .bottom {
        position: absolute;
        width: 330px;
        top: 87vh;
        left: 50vw;
        margin-left: -165px;
    }
    
    .icon-blocks {
        display: inline-block;
        text-align: center;
    }
    
    .icon-blocks img {
        width: 40px;
        margin: 0 35px;
    }
    
    .icon-blocks p {
        font-size: 13px;
        margin-top: 5px;
    }
    
    ::placeholder { 
        font-size: 13px;
        color: rgb(209, 209, 209);
    }
}